import React from "react";
import { Link } from "gatsby";
import "./Footer.css";

function Footer({ config }) {
  // const url = config.siteRss;
  const { copyright } = config;
  if (!copyright) {
    return null;
  }
  return (
    <footer className="footer">
      <h4 className="copyright">{copyright}</h4>
      <span className="dot-separator">•</span>
      <Link to="/terms"className="footerLink">Terms & Conditions</Link>
    </footer>
  );
}

export default Footer;
