import React, { useState, useEffect, useRef } from "react";
import { Link } from "gatsby";
import Hamburger from "../../../static/hamburger-menu.svg";
import CloseMenu from "../../../static/close-menu.svg";

import "./Header.css";

const Header = ({config}) => {

    const [open, setOpen] = useState(false);
    const node = useRef();
    useEffect(() => {
        const listener = event => {
          if (!node.current || node.current.contains(event.target)) {
            return;
          }
          setOpen(false);
        };
        document.addEventListener('mousedown', listener);
        return () => {
          document.removeEventListener('mousedown', listener);
        };
      });


    return(
    <header className="header-container">
        <div className="header-content">
            <Hamburger className="menu-icon" onClick={() => setOpen(!open)}/>
            <Link  to="/" key="Home Page" className="link header-logo">
                <h1> Fire Budgets</h1>
            </Link>   
            <nav ref={node} id="main-menu" class={`main-menu ${open ? "main-menu-open" : "main-menu-closed"}`}>
                <div className="menu-header">
                    <h1>Menu</h1>
                    <CloseMenu className="close-menu"  onClick={() => setOpen(!open)}/>
                </div>
                <ul className="menu-item-list">
                    <li>
                        <Link  to="/" key="home page" className="link">
                            <p>Home</p>
                        </Link>  
                    </li>
                    <li>
                        <Link  to="/articles/" key="articles" className="link">
                            <p>Articles</p>
                        </Link>  
                    </li>
                    <li>
                        <Link  to="/budgeting/" key="budgeting" className="link">
                            <p>Budgeting</p>
                        </Link>  
                    </li>
                    <li>
                        <Link  to="/income/" key="income" className="link">
                            <p>Income</p>
                        </Link>  
                    </li>
                    <li>
                        <Link  to="/investing/" key="investing" className="link">
                            <p>Investing</p>
                        </Link>  
                    </li>
                    <li>
                        <Link  to="/about" key="about" className="link">
                            <p>About</p>
                        </Link>  
                    </li>
                    <li>
                        <Link  to="/contact" key="contact" className="link">
                            <p>Contact</p>
                        </Link>  
                    </li>
                </ul>
            </nav>

        </div>

    </header>
    );
}

export default Header;