import React from "react";
import { ToastProvider } from 'react-toast-notifications';
import { Helmet } from "react-helmet";
import config from "../../data/SiteConfig";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import "@fontsource/roboto";
import "@fontsource/open-sans";

import "./index.css";

export default function MainLayout({ children }) {
  return (
    <ToastProvider>
      <div className="layout-container">
        <Helmet>
          <meta name="description" content={config.siteDescription} />
          <html lang="en" />
        </Helmet>
        <Header config={config} />
        {children}
        <Footer config={config}/>
      </div>
    </ToastProvider>
    
  );
}
